import classNames from 'classnames';

type LoadingSpinnerProps = {
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
};

export const LoadingSpinner = ({
  className = '',
  size = 'xlarge',
}: LoadingSpinnerProps) => {
  return (
    <div
      className={classNames(
        'relative animate-rotate rounded-full',
        {
          'size-3': size === 'small',
          'size-4': size === 'medium',
          'size-5': size === 'large',
          'size-10': size === 'xlarge',
        },
        className
      )}
    >
      <div
        className={classNames(
          'absolute inset-0 size-full animate-prixClipFix rounded-full border-secondary-700',
          {
            'border-2': size === 'small',
            'border-[3px]': size === 'medium',
            'border-4': size === 'large',
            'border-[8px]': size === 'xlarge',
          }
        )}
      />
    </div>
  );
};
